import safeStorage from '@github-ui/safe-storage'
import {type CodeViewOption, codeWrappingName, useCodeViewOptions} from '@github-ui/use-code-view-options'
import {CheckIcon} from '@primer/octicons-react'
import {ActionList} from '@primer/react'
import {startTransition, useCallback} from 'react'

import {updateExpandPreferences} from '../../../hooks/use-update-panel-expand-preferences'

const safeLocalStorage = safeStorage('localStorage')

export default function SettingsMenuItems({symbolsEnabled}: {symbolsEnabled: boolean}) {
  const {codeFoldingOption, codeWrappingOption, codeCenterOption, openSymbolsOption} = useCodeViewOptions()

  return (
    <>
      <OptionsElement option={codeFoldingOption} />
      <OptionsElement option={codeWrappingOption} />
      <OptionsElement option={codeCenterOption} />
      {symbolsEnabled && <OptionsElement option={openSymbolsOption} />}
    </>
  )
}

export function OptionsElement({option}: {option: CodeViewOption}) {
  const onSelect = useCallback(() => {
    //need to start the transition because we might be swapping between no virtualization/virtualized versions,
    //and we lazy load components in either approach
    startTransition(() => option.setEnabled(!option.enabled))
    safeLocalStorage.setItem(option.name, String(!option.enabled))
    if (option.name === codeWrappingName) updateExpandPreferences(null, null, !option.enabled)
  }, [option])

  return (
    <ActionList.Item role="menuitemcheckbox" aria-checked={option.enabled} key={option.name} onSelect={onSelect}>
      <ActionList.LeadingVisual>{option.enabled && <CheckIcon />}</ActionList.LeadingVisual>
      {option.label}
    </ActionList.Item>
  )
}

try{ SettingsMenuItems.displayName ||= 'SettingsMenuItems' } catch {}
try{ OptionsElement.displayName ||= 'OptionsElement' } catch {}