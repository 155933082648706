import {jsonRoute} from '@github-ui/react-core/json-route'
import {registerNavigatorApp} from '@github-ui/react-core/register-app'

import {App} from './App'
import CodeView from './pages/CodeView'

registerNavigatorApp('react-code-view', () => ({
  App,
  routes: [
    jsonRoute({path: '/:owner/:repo/tree/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/blob/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/blame/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/edit/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/new/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/new/:branch/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/tree/delete/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/delete/:branch/:path/*', Component: CodeView, shouldNavigateOnError: true}),

    // We cannot use '/:owner/:repo/*' because it could intercept routes to other react apps, like '/:owner/:repo/pushes'
    jsonRoute({path: '/:owner/:repo', Component: CodeView, shouldNavigateOnError: true}),
    jsonRoute({path: '/:owner/:repo/tree/:branch/*', Component: CodeView, shouldNavigateOnError: true}),
  ],
}))
